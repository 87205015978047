import React from 'react';
import { Icon } from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const Map = ({
  latitude = 51.4975,
  longitude = 0.1357,
  zoom = 13,
  scrollWheelZoom = false,
  tooltipText,
}) => {
  const position = [latitude, longitude];

  const marker = new Icon({
    iconUrl: '/marker.svg',
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
  });

  if (typeof window !== 'undefined') {
    return (
      <MapContainer
        center={position}
        zoom={zoom}
        scrollWheelZoom={scrollWheelZoom}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/about-carto/">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={marker}>
          {tooltipText && <Popup>{tooltipText}</Popup>}
        </Marker>
      </MapContainer>
    );
  }
  return null;
};

export default Map;
