import React from 'react';
import { useStatePersist } from 'use-state-persist';

import { Link } from 'gatsby';

import ScreenReader from '../components/ScreenReader';

const SmartFeedJobCard = ({
  job,
  apiKey,
  viewBySelection,
  siteConfig,
  // trackEvent,
}) => {
  const [savedJobs, setSavedJobs] = useStatePersist('@savedJobs_' + apiKey, []);

  function unSaveJob(id) {
    const newSavedJobsArray = savedJobs.filter((e) => e !== id.toString());
    setSavedJobs(newSavedJobsArray);
    // if (trackEvent) {
    //   trackEvent({
    //     eventCategory: 'SmartFeedListComponent',
    //     eventAction: 'unSaveJob',
    //     eventLabel: encodeURIComponent(id),
    //   });
    // }
  }

  function saveJob(id) {
    setSavedJobs((savedJobsOld) => [...savedJobsOld, id]);
    // if (trackEvent) {
    //   trackEvent({
    //     eventCategory: 'SmartFeedListComponent',
    //     eventAction: 'saveJob',
    //     eventLabel: encodeURIComponent(id),
    //   });
    // }
  }

  const calculateSalary = function calculateSalary(
    Currency,
    SalaryFrom,
    SalaryTo,
    SalaryType,
    SalaryPeriod
  ) {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    let NewSalary;
    let NewSalaryFrom;
    let NewSalaryTo;

    let currencyFormatted = Currency;

    if (Currency === '&pound;') {
      currencyFormatted = '£';
    }

    if (SalaryTo === 0) {
      NewSalary = SalaryType;
    } else if (SalaryFrom === SalaryTo) {
      if (SalaryFrom >= 1000) {
        // Remove decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        // Add two decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      NewSalary = NewSalaryFrom + ' ' + SalaryPeriod;
    } else {
      if (SalaryFrom >= 1000) {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      if (SalaryTo >= 1000) {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed());
      } else {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2));
      }

      NewSalary = NewSalaryFrom + ' - ' + NewSalaryTo + ' ' + SalaryPeriod;
    }
    return NewSalary;
  };

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = new Object({});
    dateFormatOptions.weekday = 'long';
    dateFormatOptions.year = 'numeric';
    dateFormatOptions.month = 'short';
    dateFormatOptions.day = 'numeric';
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  };

  const getSummary = function getSummary(summary, description) {
    if (summary) {
      return summary;
    } else {
      let alteredDescription = description.replace(/<[^>]*>?/gm, '');
      let tempSummary = alteredDescription.substring(0, 397);
      if (tempSummary.length === 397) {
        tempSummary += '...';
      }
      return tempSummary;
    }
  };

  const slugify = function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters

      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-'); // Replace multiple - with single -
  };

  const onError = (e) => {
    e.target.onerror = null;
    e.target.src = '/logos/default.png';
  };

  return (
    <div
      className={`py-2 col-12 column ${
        viewBySelection === 'grid' ? 'col-md-6' : ''
      }`}
    >
      <div className="job-card card" id={new Buffer(job.Id).toString('base64')}>
        <div className="job-card-details">
          <div className="d-flex justify-content-between">
            <div className="job-card-header">
              <Link
                to={
                  `/vacancies/vacancy/` +
                  new Buffer(job.Id).toString('base64') +
                  '/' +
                  slugify(job.JobTitle)
                }
                className="job-card-title"
              >
                {job.JobTitle}
              </Link>
            </div>
            <div className="job-card-actions">
              <div
                title="Save this job for later"
                className="job-card-savejob"
                onClick={() => {
                  if (
                    savedJobs.filter((e) => e == job.Id.toString()).length > 0
                  ) {
                    unSaveJob(job.Id);
                  } else {
                    saveJob(job.Id);
                  }
                }}
              >
                <button className="btn btn-link">
                  {savedJobs.filter((e) => e == job.Id.toString()).length >
                  0 ? (
                    <span className="fas fa-heart"></span>
                  ) : (
                    <span className="far fa-heart"></span>
                  )}
                </button>
              </div>
              <div className="job-card-reader">
                <ScreenReader
                  text={`${job.JobTitle}, 
                  Salary for this role is ${calculateSalary(
                    job.Currency,
                    job.SalaryFrom,
                    job.SalaryTo,
                    job.SalaryType,
                    job.SalaryPeriod
                  )}, 
                  This Adverts Closing Date expires on ${FormatDateTime(
                    job.ExpiryDate
                  )},
                  this role is based in ${job.Location}, Postcode ${
                    job.PostCode
                  } in the ${job.Country}. 
                  part of ${job.JobCategory}, 
                  the job type is ${job.JobType}.
                  the reference for this role is ${job.AdvertReference}.
                  Please click the more details button below to find out more.`}
                />
              </div>
            </div>
          </div>
          <div className="job-card-location-logo">
            <div className="job-card-meta">
              {/* <div className="job-card-meta-item">
                <div className="job-card-location">{job.Location}</div>
              </div> */}
              <div className="job-card-meta-item">
                <span>Salary :</span>
                {calculateSalary(
                  job.Currency,
                  job.SalaryFrom,
                  job.SalaryTo,
                  job.SalaryType,
                  job.SalaryPeriod
                )}
              </div>
              <div className="job-card-meta-item">
                <span>Closing Date:</span>
                {FormatDateTime(job.ExpiryDate)}
              </div>
              <div className="job-card-meta-item">
                <span>Location:</span>
                {job.Location ? job.Location + ', ' : ''}
                {job.SubLocation ? job.SubLocation + ', ' : ''}
                {job.PostCode ? job.PostCode + ', ' : ''}
                {job.Country ? job.Country : ''}
              </div>
              <div className="job-card-meta-item">
                <span>Category:</span>
                {job.JobCategory}
              </div>
              <div className="job-card-meta-item">
                <span>Job Type:</span>
                {job.JobType}
              </div>
              <div className="job-card-meta-item">
                <span>Reference:</span>
                {job.AdvertReference}
              </div>
            </div>
          </div>
          <div className="job-card-buttons-wrap">
            <Link
              className="job-card-button btn--secondary"
              to={
                `/vacancies/vacancy/` +
                new Buffer(job.Id).toString('base64') +
                '/' +
                slugify(job.JobTitle)
              }
            >
              More details
            </Link>
            <Link
              className="job-card-button btn--primary"
              to={
                '/vacancies/vacancy/' +
                new Buffer(job.Id).toString('base64') +
                '/' +
                slugify(job.JobTitle) +
                '?action=applyNow' +
                '&' +
                window.location.search.replace('?', '')
              }
            >
              Apply Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartFeedJobCard;
