import React, { useState, useEffect } from 'react';
import { useStatePersist } from 'use-state-persist';

import fetchJsonp from 'fetch-jsonp';

import SmartFeedJobCard from '../components/SmartFeedJobCard';

import 'url-search-params-polyfill';

require('es6-promise').polyfill();

const setVacancySavedLoadingBackup = function setVacancySavedLoadingBackup(
  loading,
  vacancySavedLoading
) {
  if (loading) {
    return true;
  }

  return false;
};

let prevCount = 0;
let currentCount = 0;

const SavedJobsList = ({
  siteConfig,
  vacancySavedLoading = true,
  apiKey,
  setVacancySavedLoading = setVacancySavedLoadingBackup,
  smartfeedCompanyData = {},
  // trackEvent,
}) => {
  const [vacancySaved, setVacancySaved] = useState([]);
  const [vacancySavedTitle, setVacancySavedTitle] = useState(
    'Loading saved vacancies...'
  );
  const [initialLoading, setInitialLoading] = useState(true);
  const [iconClicked, setIconClicked] = useState(false);

  const [savedJobs, setSavedJobs] = useStatePersist('@savedJobs_' + apiKey, []);

  const [vacsCount, setVacsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadMoreText, setLoadMoreText] = useState(false);

  function unSaveJob(id) {
    const newSavedJobsArray = savedJobs.filter((e) => e !== id.toString());
    setSavedJobs(newSavedJobsArray);
    // trackEvent({
    //   eventAction: 'unSaveJob',
    //   eventLabel: encodeURIComponent(id),
    // });
  }

  function checkJobHasExpired(JSONDate) {
    if (JSONDate) {
      let expDate = new Date(parseInt(JSONDate.substr(6), 10));
      let now = new Date();

      if (expDate > now) {
        return false;
      }
    }
    return true;
  }

  const fetchVacancies = async (
    state,
    name,
    e,
    loadMore,
    postingTypeSelection
  ) => {
    // State of filters, Name of searched filter, event, load more boolean
    setLoadMoreText('Loading your saved jobs...');

    if (!loadMore) {
      prevCount = 0;
      currentCount = 0;
    }

    function getAdvertData(jobs, prevCount, currentCount) {
      return Promise.all(
        jobs.slice(prevCount, currentCount).map(async (id) => {
          return fetchJsonp(
            siteConfig.sfAPIEndpoint + '/CareerPage/GetItem?id=' + id,
            {
              timeout: 15000,
            }
          )
            .then(function(response) {
              return response.json();
            })
            .catch(function(error) {
              console.error(error);
              unSaveJob(id);
            });
        })
      );
    }

    try {
      prevCount = currentCount;
      currentCount = currentCount + 12;

      await getAdvertData(savedJobs, prevCount, currentCount).then(function(
        data
      ) {
        data.forEach((job) => {
          if (checkJobHasExpired(job.ExpiryDate)) {
            unSaveJob(job.Id);
            return;
          }
        });

        if (loadMore) {
          setVacancySaved(vacancySaved.concat(data));
        } else {
          setVacancySaved(data);
        }

        if (savedJobs.length === 0) {
          setVacancySavedTitle("We couldn't find any saved jobs");
        } else if (savedJobs.length === 1) {
          setVacancySavedTitle('You have ' + savedJobs.length + ' saved job');
        } else {
          setVacancySavedTitle('You have ' + savedJobs.length + ' saved jobs');
        }

        setInitialLoading(false);

        setLoading(false);
        setVacancySavedLoading(false);

        setLoadMoreText('Load More');
        setVacsCount(savedJobs.length);
      });
    } catch (error) {
      setLoading(false);
      setVacancySavedLoading(false);
      console.error(error.message, 'error');
    }
  };

  const loadMore = function loadMore() {
    fetchVacancies({}, undefined, undefined, true);
  };

  useEffect(() => {
    fetchVacancies({}, undefined, undefined, false);
  }, [savedJobs]);

  return (
    <div className={vacancySavedLoading ? 'vacancies-landing-loading' : ''}>
      {!vacancySavedLoading &&
      smartfeedCompanyData &&
      smartfeedCompanyData.CompanyName ? (
        <>
          <div
            style={{
              backgroundColor: '#264bd6',
            }}
          >
            <div className="homepage-banner">
              <div className="container">
                <div className="bannerWrapper">
                  <h1 className="h1-homepage">Your Saved Jobs</h1>
                  <h3>Work for The Salvation Army</h3>
                  <a href="/vacancies/" className="btn vf-banner-search-btn">
                    SEARCH FOR MORE JOBS
                  </a>
                </div>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#ffffff"
                fillOpacity="1"
                d="M0,192L80,186.7C160,181,320,171,480,170.7C640,171,800,181,960,165.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
              ></path>
            </svg>
          </div>

          <section className={`main-vacs-wrap`}>
            <div>
              <div className="container">
                <div className="row clearfix vf-minus">
                  <div className="col-12 column job-vacancies-col p-0">
                    <h3 className="vacancies-landing__header__heading">
                      {vacancySavedTitle}
                    </h3>
                    <div className="vf-careers-site">
                      <div id="VF_vacancies">
                        <div className="row VF_vacancies_main_wrap">
                          <div className="col-12 p-0">
                            <div className="main-section">
                              <div className="row search-container-wrap">
                                <div className="col-12 inner-content grid-section box-theme-2 joblist-section">
                                  <div
                                    id="VF-vacancies-wrapper"
                                    className="VF_vacancies_list"
                                  >
                                    <div className="row">
                                      {loading ? (
                                        'Loading vacancies...'
                                      ) : (
                                        <>
                                          {vacancySaved &&
                                          vacancySaved !== [] &&
                                          vacancySaved !== '[]' &&
                                          !initialLoading ? (
                                            <>
                                              {vacancySaved &&
                                              vacancySaved.length > 0 ? (
                                                vacancySaved.map((job, i) => (
                                                  <SmartFeedJobCard
                                                    job={job}
                                                    key={i}
                                                    viewBySelection="list"
                                                    apiKey={apiKey}
                                                    siteConfig={siteConfig}
                                                    // trackEvent={trackEvent}
                                                  />
                                                ))
                                              ) : (
                                                <div className="job-card card job-card-no-results">
                                                  <div className="job-card-details">
                                                    <div className="no-results">
                                                      Click the heart icon{' '}
                                                      <div className="text-danger d-inline">
                                                        <div
                                                          title="Save this job for later"
                                                          className="job-card-savejob d-inline"
                                                          role="button"
                                                          onClick={() => {
                                                            setIconClicked(
                                                              !iconClicked
                                                            );
                                                          }}
                                                        >
                                                          {iconClicked ? (
                                                            <span className="fas fa-heart"></span>
                                                          ) : (
                                                            <span className="far fa-heart"></span>
                                                          )}
                                                        </div>
                                                      </div>{' '}
                                                      on the Job Cards to add
                                                      adverts to your Saved
                                                      Jobs.
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div className="no-vacancies">
                                              Loading vacancies...
                                            </div>
                                          )}

                                          <div className="load-more-wrap col-12">
                                            {currentCount < vacsCount && (
                                              <a
                                                className="nav-action-sm"
                                                role="button"
                                                onClick={() => loadMore()}
                                              >
                                                {loadMoreText}
                                              </a>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="page-loading-block">
          <div className="loading-panel">
            <div className="loading-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <span className="loading-text">
              Please wait while we load the vacancies available...
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SavedJobsList;
