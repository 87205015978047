import React from "react";

const base64url = require("base64url");

const VF_Share_Buttons = (props) => {
  const { vacancy, apiKey, siteConfig, vacancyTitle, text } = props;

  return (
    <div className="vf-SocialButtons">
      <span>{text ? text : "Share this role instead:"}</span>
      <a
        data-sharelinkend=""
        href={
          "https://www.facebook.com/sharer/sharer.php?u=" +
          siteConfig.careersSiteDomain +
          "/vacancies/vacancy/" +
          base64url(vacancy.Id) +
          "%3FExtraData%3D" +
          encodeURIComponent(
            base64url(
              vacancy.JobTitle +
                "|" +
                vacancy.Company +
                "|" +
                vacancy.CompanyLogoPath +
                "|" +
                vacancy.Summary
            )
          ) +
          "%26utm_source%3Dsocial%26utm_campaign%3Dsharing_buttons"
        }
        target="noopener noreferrer"
        rel="nofollow"
        className="vf-facebook-sharing-button vf-shareButton vf-activate-sharing-button"
      >
        <span className="fab fa-facebook"></span>
      </a>
      <a
        href={
          "https://twitter.com/intent/tweet?text=" +
          vacancyTitle +
          " - " +
          siteConfig.careersSiteDomain +
          "/vacancies/vacancy/" +
          base64url(vacancy.Id) +
          "%3FExtraData%3D" +
          encodeURIComponent(
            base64url(
              vacancy.JobTitle +
                "|" +
                vacancy.Company +
                "|" +
                vacancy.CompanyLogoPath +
                "|" +
                vacancy.Summary
            )
          ) +
          "%26utm_source%3Dsocial%26utm_campaign%3Dsharing_buttons"
        }
        target="noopener noreferrer"
        rel="nofollow"
        className=" vf-twitter-sharing-button vf-shareButton vf-activate-sharing-button"
      >
        <span className="fab fa-twitter"></span>
      </a>
      <a
        href={
          "https://www.linkedin.com/shareArticle?mini=true&url=" +
          encodeURIComponent(siteConfig.careersSiteDomain) +
          encodeURIComponent("/vacancies/vacancy/") +
          base64url(vacancy.Id) +
          "%3FExtraData%3D" +
          encodeURIComponent(
            base64url(
              vacancy.JobTitle +
                "|" +
                vacancy.Company +
                "|" +
                vacancy.CompanyLogoPath +
                "|" +
                vacancy.Summary
            )
          ) +
          "%26utm_source%3Dsocial%26utm_campaign%3Dsharing_buttons&title%3D" +
          encodeURIComponent(vacancyTitle) +
          "%26summary%3D%26source%3D"
        }
        target="noopener noreferrer"
        rel="nofollow"
        className=" vf-linkedin-sharing-button vf-shareButton vf-activate-sharing-button"
      >
        <span className="fab fa-linkedin"></span>
      </a>
      <a
        href={
          "mailto:?subject=Check out this vacancy from " +
          vacancy.Company +
          "&body=Check out this " +
          vacancy.JobTitle +
          " vacancy " +
          " - " +
          siteConfig.careersSiteDomain +
          "/vacancies/vacancy/" +
          base64url(vacancy.Id) +
          "%3FExtraData%3D%26utm_source%3Dsocial%26utm_campaign%3Dsharing_buttons"
        }
        target="noopener noreferrer"
        rel="nofollow"
        className=" vf-email-sharing-button vf-shareButton vf-activate-sharing-button"
      >
        <span className="fas fa-envelope"></span>
      </a>
    </div>
  );
};

export default VF_Share_Buttons;
