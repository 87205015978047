import fetchJsonp from 'fetch-jsonp';

var Scroll = require('react-scroll');
// var Element = Scroll.Element
var scroller = Scroll.scroller;

// var $ = function () {
//   alert("nope")
// }

export const activateApplicationForm = function activateApplicationForm(
  siteConfig,
  setting,
  setshowApplication,
  setApplicationFormLoading,
  setMiniAppFormJSON,
  setGdprStatementJSON,
  setJobSourcesJSON,
  setShowMiniAppForm,
  setShowMiniAppFormFrameLoading,
  encadvertid,
  tkn,
  advertRequiresCv,
  MiniAppError,
  setMiniAppError,
  miniAppFormToken,
  setMiniAppFormToken,
  isInlineForm,
  companyId
  // trackEvent,
) {
  function GetGdprStatementAjax(
    siteConfig,
    dataObj,
    setGdprStatementJSON,
    MiniAppError,
    setMiniAppError,
    miniAppFormToken,
    setMiniAppFormToken
    // trackEvent,
  ) {
    const dataObjQueryString = Object.keys(dataObj)
      .map((key) => key + '=' + dataObj[key])
      .join('&');

    fetchJsonp(
      `${siteConfig.sfAPIEndpoint}/CareerPage/GetGdprStatement?${dataObjQueryString}`,
      {
        timeout: 60000,
      }
    )
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        // if (trackEvent) {
        //   trackEvent({
        //     eventCategory: 'MiniAppFormFunctions',
        //     eventAction: 'GDPRStatementRetrieved',
        //   });
        // }
        setGdprStatementJSON(data);
      })
      .catch(function(ex) {
        setMiniAppError(ex);
        setApplicationFormLoading(false);
        console.error(ex, 'error');
      });
  }

  //make mini app form call
  function GetMiniAppFormAjax(
    siteConfig,
    dataObj,
    setMiniAppFormJSON,
    setShowMiniAppForm,
    advertRequiresCv,
    MiniAppError,
    setMiniAppError,
    miniAppFormToken,
    setMiniAppFormToken
    // trackEvent,
  ) {
    const dataObjQueryString = Object.keys(dataObj)
      .map((key) => key + '=' + dataObj[key])
      .join('&');

    setshowApplication(setting);

    scroller.scrollTo('ApplicationScrollTarget', {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -150,
    });

    fetchJsonp(
      `${siteConfig.sfAPIEndpoint}/CareerPage/GetMiniAppFormJson?${dataObjQueryString}`,
      {
        timeout: 60000,
      }
    )
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        // if (trackEvent) {
        //   trackEvent({
        //     eventCategory: 'MiniAppFormFunctions',
        //     eventAction: 'MiniAppFormLoaded',
        //   });
        // }
        setMiniAppFormToken(data.token);

        setMiniAppFormJSON(data);

        setApplicationFormLoading(false);
        setShowMiniAppForm(true);
      })
      .catch(function(ex) {
        setMiniAppError(ex);
        setApplicationFormLoading(false);
        console.error(ex, 'error');
      });
  }

  function GetJobSourcesAjax(
    dataObj,
    setJobSourcesJSON,
    MiniAppError,
    setMiniAppError,
    setApplicationFormLoading
    // trackEvent,
  ) {
    const dataObjQueryString = Object.keys(dataObj)
      .map((key) => key + '=' + dataObj[key])
      .join('&');

    fetchJsonp(
      `${siteConfig.sfAPIEndpoint}/CareerPage/GetJobSources?${dataObjQueryString}`,
      {
        timeout: 60000,
      }
    )
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        // if (trackEvent) {
        //   trackEvent({
        //     eventCategory: 'MiniAppFormFunctions',
        //     eventAction: 'JobSourcesLoaded',
        //   });
        // }
        setJobSourcesJSON(data);
      })
      .catch(function(ex) {
        setMiniAppError(ex);
        setApplicationFormLoading(false);
        console.error(ex, 'error');
      });
  }

  //set up mini app form
  function GetMiniAppForm(
    siteConfig,
    encadvertid,
    tkn,
    advertRequiresCv,
    MiniAppError,
    setMiniAppError
    // trackEvent,
  ) {
    setMiniAppFormJSON({});
    setGdprStatementJSON({});
    setJobSourcesJSON({});
    setApplicationFormLoading(true);
    setShowMiniAppFormFrameLoading(false);
    setShowMiniAppForm(false);
    setMiniAppFormToken(tkn);

    var dataObj = {
      id: encadvertid,
      token: miniAppFormToken || tkn,
    };

    GetMiniAppFormAjax(
      siteConfig,
      dataObj,
      setMiniAppFormJSON,
      setShowMiniAppForm,
      advertRequiresCv,
      MiniAppError,
      setMiniAppError,
      miniAppFormToken,
      setMiniAppFormToken
      // trackEvent,
    );

    var dataObjStatement = {
      AdvertId: encadvertid,
    };

    GetGdprStatementAjax(
      siteConfig,
      dataObjStatement,
      setGdprStatementJSON,
      MiniAppError,
      setMiniAppError,
      miniAppFormToken,
      setMiniAppFormToken
      // trackEvent,
    );

    var dataObjJobSources = {
      id: companyId,
    };

    GetJobSourcesAjax(
      dataObjJobSources,
      setJobSourcesJSON,
      MiniAppError,
      setMiniAppError,
      setApplicationFormLoading
      // trackEvent,
    );
  }

  GetMiniAppForm(
    siteConfig,
    encadvertid,
    tkn,
    advertRequiresCv,
    MiniAppError,
    setMiniAppError
    // trackEvent,
  );
};

export const SubmitStartApplication = function SubmitStartApplication(
  siteConfig,
  values,
  setShowMiniAppForm,
  setShowMiniAppFormFrameLoading,
  setMiniAppFormIframe,
  miniAppFormSubmitted,
  setMiniAppFormSubmitted,
  MiniAppError,
  setMiniAppError,
  miniAppFormToken,
  setMiniAppFormToken,
  hasCVUploadedStatus,
  setHasCVUploadedStatus
  // trackEvent,
) {
  scroller.scrollTo('ApplicationScrollTarget', {
    duration: 500,
    delay: 0,
    smooth: true,
    offset: -150,
  });

  setShowMiniAppForm(true);
  setShowMiniAppFormFrameLoading(true);
  setMiniAppFormSubmitted(true);
  setMiniAppFormIframe('');

  let dataObj = { ...values };

  console.debug('Submitted form values', dataObj);

  if (dataObj.cv_upload && dataObj.cv_upload.name) {
    // trackEvent({
    //   eventCategory: 'MiniAppFormFunctions',
    //   eventAction: 'submitStartApplicationAjaxForCv',
    // });
    submitStartApplicationAjaxForCv(
      siteConfig,
      dataObj,
      setShowMiniAppFormFrameLoading,
      setMiniAppFormIframe,
      MiniAppError,
      setMiniAppError,
      setMiniAppFormSubmitted,
      miniAppFormToken,
      setMiniAppFormToken,
      hasCVUploadedStatus,
      setHasCVUploadedStatus
      // trackEvent,
    );
  } else {
    // trackEvent({
    //   eventCategory: 'MiniAppFormFunctions',
    //   eventAction: 'submitStartApplicationAjax',
    // });
    dataObj.cv_upload = '';
    dataObj.cover_upload = '';

    setHasCVUploadedStatus(true);

    submitStartApplicationAjax(
      siteConfig,
      dataObj,
      setShowMiniAppFormFrameLoading,
      setMiniAppFormIframe,
      MiniAppError,
      setMiniAppError,
      setMiniAppFormSubmitted,
      miniAppFormToken,
      setMiniAppFormToken
      // trackEvent,
    );
  }
};

function submitStartApplicationAjax(
  siteConfig,
  dataObj,
  setShowMiniAppFormFrameLoading,
  setMiniAppFormIframe,
  MiniAppError,
  setMiniAppError,
  setMiniAppFormSubmitted,
  miniAppFormToken,
  setMiniAppFormToken
  // trackEvent,
) {
  const newDataObj = {
    data: JSON.stringify(dataObj),
    token: dataObj.token,
    advertId: dataObj.advertId,
  };

  const dataObjQueryString = Object.keys(newDataObj)
    .map((key) => key + '=' + newDataObj[key])
    .join('&');

  fetchJsonp(
    `${siteConfig.sfAPIEndpoint}/CareerPage/SubmitStartApplication?${dataObjQueryString}`,
    {
      timeout: 60000,
    }
  )
    .then(function(response) {
      return response.json();
    })
    .then(function(response) {
      console.debug(
        'submitStartApplicationAjax response => ',
        response,
        response.Data
      );
      if (response && response.Data && response.Data.success === false) {
        setMiniAppFormSubmitted(false);
        setMiniAppFormToken(
          response && response.Data ? response.Data.token : ''
        );
        setMiniAppFormIframe('');
        setShowMiniAppFormFrameLoading(false);
        console.error(
          'submitStartApplicationAjax error => ',
          response.Data.responseType + ' - ' + response.Data.responseText
        );
        setMiniAppError(response);
      } else if (response && !response.Data) {
        // trackEvent({
        //   eventCategory: 'MiniAppFormFunctions',
        //   eventAction: 'submitStartApplicationAjax_Completed',
        // });
        setMiniAppFormSubmitted(true);

        setMiniAppFormToken('');

        var iframeCode = response;

        function blankify(html) {
          var root = document.createElement('div'),
            iframes = root.getElementsByTagName('iframe');

          root.innerHTML = html;

          Array.prototype.forEach.call(iframes, function(e) {
            e.setAttribute('title', 'Application Form');
            e.setAttribute('id', 'CareersPage_iFrame');
            e.setAttribute('height', '1000px');
          });

          return root.innerHTML;
        }

        setMiniAppError('');

        console.debug('iFrame response => ', iframeCode);

        setMiniAppFormIframe(blankify(iframeCode));

        setShowMiniAppFormFrameLoading(false);
      } else {
        setMiniAppFormSubmitted(false);
        setMiniAppFormToken(
          response && response.Data ? response.Data.token : ''
        );
        setMiniAppFormIframe('');
        setShowMiniAppFormFrameLoading(false);
        console.error(
          'submitStartApplicationAjax error => ',
          'Unhandled response from the SmartFeed'
        );
        setMiniAppError('Unhandled response from the SmartFeed');
      }
    })
    .catch(function(ex) {
      setMiniAppFormSubmitted(false);
      console.error(ex, 'error');
      setMiniAppError(ex);
      setMiniAppFormIframe('');
      setShowMiniAppFormFrameLoading(false);
    });
}

function GetUploadLink(
  siteConfig,
  encResponseId,
  file,
  token,
  isCv,
  setShowMiniAppFormFrameLoading,
  setMiniAppFormIframe,
  MiniAppError,
  setMiniAppError,
  setMiniAppFormSubmitted,
  miniAppFormToken,
  setMiniAppFormToken,
  hasCVUploadedStatus,
  setHasCVUploadedStatus
) {
  return new Promise(function(resolve, reject) {
    var filename = file.name;
    var responseFileTypeEnum = 0;
    if (!isCv) {
      responseFileTypeEnum = 1;
    }

    let dataObj = {
      encResponseId: encResponseId,
      responseFileTypeEnum: responseFileTypeEnum,
      fileName: filename,
      token: token,
    };

    const dataObjQueryString = Object.keys(dataObj)
      .map((key) => key + '=' + dataObj[key])
      .join('&');

    fetchJsonp(
      `${siteConfig.sfAPIEndpoint}/CareerPage/GetFileUploadUrl?${dataObjQueryString}`,
      {
        timeout: 60000,
      }
    )
      .then(function(response) {
        return response.json();
      })
      .then(function(response) {
        if (response != null && !response.presignedUrl) {
          setMiniAppFormToken(
            response && response.Data ? response.Data.token : ''
          );
          console.warn(response);
          setMiniAppFormSubmitted(false);
          setMiniAppError(response);
          setMiniAppFormIframe('');
          setShowMiniAppFormFrameLoading(false);
        } else {
          setMiniAppFormToken(response.token);

          return UploadSingleFile(file, response.presignedUrl)
            .then(function(data) {
              if (isCv) {
                CheckIfCVHasUploaded(
                  siteConfig,
                  {
                    token: response.token,
                    encResponseId: encResponseId,
                  },
                  hasCVUploadedStatus,
                  setHasCVUploadedStatus,
                  setMiniAppFormToken,
                  setMiniAppError,
                  resolve,
                  response
                );
              } else {
                resolve(response);
              }
            })
            .catch(function(err) {
              console.warn(response);
              setMiniAppFormSubmitted(false);
              setMiniAppError(response);
              setMiniAppFormIframe('');
              setShowMiniAppFormFrameLoading(false);
              reject(err);
            });
        }
      })
      .catch(function(ex) {
        setMiniAppError(ex);
        console.error(ex, 'error');
      });
  });
}

function submitStartApplicationAjaxForCv(
  siteConfig,
  dataObj,
  setShowMiniAppFormFrameLoading,
  setMiniAppFormIframe,
  MiniAppError,
  setMiniAppError,
  setMiniAppFormSubmitted,
  miniAppFormToken,
  setMiniAppFormToken,
  hasCVUploadedStatus,
  setHasCVUploadedStatus
  // trackEvent,
) {
  const dataObjToSend = {
    ...dataObj,
    cv_upload:
      dataObj.cv_upload && dataObj.cv_upload.name ? dataObj.cv_upload.name : '',
    cover_upload:
      dataObj.cover_upload && dataObj.cover_upload.name
        ? dataObj.cover_upload.name
        : '',
  };

  const newDataObj = {
    data: JSON.stringify(dataObjToSend),
    token: dataObj.token,
    advertId: dataObj.advertId,
  };

  const dataObjQueryString = Object.keys(newDataObj)
    .map((key) => key + '=' + newDataObj[key])
    .join('&');

  fetchJsonp(
    `${siteConfig.sfAPIEndpoint}/CareerPage/SubmitStartApplicationCvUpload?${dataObjQueryString}`,
    {
      timeout: 60000,
    }
  )
    .then(function(response) {
      return response.json();
    })
    .then(function(response) {
      console.debug(
        'submitStartApplicationAjax response => ',
        response,
        response.Data
      );
      if (response && response.Data && response.Data.success === false) {
        setMiniAppFormSubmitted(false);
        setMiniAppFormToken(
          response && response.Data ? response.Data.token : ''
        );
        setMiniAppFormIframe('');
        setShowMiniAppFormFrameLoading(false);
        console.error(
          'submitStartApplicationAjax error => ',
          response.Data.responseType + ' - ' + response.Data.responseText
        );
        setMiniAppError(response);
      } else if (response && !response.Data) {
        // trackEvent({
        //   eventCategory: 'MiniAppFormFunctions',
        //   eventAction: 'submitStartApplicationAjaxForCv_Completed',
        // });
        var encResponseId = response.EncryptedResponseId;
        var iFrameRedirect = response.iFrameHtmlBody;
        var token = response.token;
        setMiniAppFormToken(token);

        console.debug('CV data', dataObj.cv_upload);

        GetUploadLink(
          siteConfig,
          encResponseId,
          dataObj.cv_upload,
          token,
          true,
          setShowMiniAppFormFrameLoading,
          setMiniAppFormIframe,
          MiniAppError,
          setMiniAppError,
          setMiniAppFormSubmitted,
          miniAppFormToken,
          setMiniAppFormToken,
          hasCVUploadedStatus,
          setHasCVUploadedStatus
        )
          .then(function(data) {
            if (dataObj.cover_upload && dataObj.cover_upload.name) {
              GetUploadLink(
                siteConfig,
                encResponseId,
                dataObj.cover_upload,
                data.token,
                false,
                setShowMiniAppFormFrameLoading,
                setMiniAppFormIframe,
                MiniAppError,
                setMiniAppError,
                setMiniAppFormSubmitted,
                miniAppFormToken,
                setMiniAppFormToken,
                hasCVUploadedStatus,
                setHasCVUploadedStatus
              )
                .then(function(data) {
                  setMiniAppFormSubmitted(true);

                  var iframeCode = iFrameRedirect;

                  function blankify(html) {
                    var root = document.createElement('div'),
                      iframes = root.getElementsByTagName('iframe');

                    root.innerHTML = html;

                    Array.prototype.forEach.call(iframes, function(e) {
                      e.setAttribute('title', 'Application Form');
                      e.setAttribute('id', 'CareersPage_iFrame');
                      e.setAttribute('height', '1000px');
                    });

                    return root.innerHTML;
                  }

                  setMiniAppFormIframe(blankify(iframeCode));

                  setShowMiniAppFormFrameLoading(false);
                })
                .catch(function(ex) {
                  setMiniAppError(ex);
                  setMiniAppFormSubmitted(false);
                  console.error(ex);
                  setMiniAppFormIframe('');
                  setShowMiniAppFormFrameLoading(false);
                });
            } else {
              setMiniAppFormSubmitted(true);

              var iframeCode = iFrameRedirect;

              function blankify(html) {
                var root = document.createElement('div'),
                  iframes = root.getElementsByTagName('iframe');

                root.innerHTML = html;

                Array.prototype.forEach.call(iframes, function(e) {
                  e.setAttribute('title', 'Application Form');
                  e.setAttribute('id', 'CareersPage_iFrame');
                  e.setAttribute('height', '1000px');
                });

                return root.innerHTML;
              }

              setMiniAppFormIframe(blankify(iframeCode));

              setShowMiniAppFormFrameLoading(false);
            }
          })
          .catch(function(ex) {
            setMiniAppFormSubmitted(false);
            setMiniAppError(ex);
            setMiniAppFormIframe('');
            setShowMiniAppFormFrameLoading(false);
            console.error(ex, 'error');
          });
      } else {
        setMiniAppFormSubmitted(false);
        setMiniAppFormToken(
          response && response.Data ? response.Data.token : ''
        );
        setMiniAppFormIframe('');
        setShowMiniAppFormFrameLoading(false);
        console.error(
          'submitStartApplicationAjax error => ',
          'Unhandled response from the SmartFeed'
        );
        setMiniAppError('Unhandled response from the SmartFeed');
      }
    })
    .catch(function(ex) {
      setMiniAppFormSubmitted(false);
      setMiniAppError(ex);
      setMiniAppFormIframe('');
      setShowMiniAppFormFrameLoading(false);
      console.error(ex, 'error');
    });
}

function CheckIfCVHasUploaded(
  siteConfig,
  dataObj,
  hasCVUploadedStatus,
  setHasCVUploadedStatus,
  setMiniAppFormToken,
  setMiniAppError,
  resolve,
  data
) {
  function runCheck(
    siteConfig,
    dataObj,
    hasCVUploadedStatus,
    setHasCVUploadedStatus,
    setMiniAppFormToken
  ) {
    const newDataObj = {
      token: dataObj.token,
      encResponseId: dataObj.encResponseId,
    };

    const dataObjQueryString = Object.keys(newDataObj)
      .map((key) => key + '=' + newDataObj[key])
      .join('&');

    fetchJsonp(
      `${siteConfig.sfAPIEndpoint}/CareerPage/HasCvUploaded?${dataObjQueryString}`,
      {
        timeout: 60000,
      }
    )
      .then(function(response) {
        return response.json();
      })
      .then(function(response) {
        if (response) {
          setMiniAppFormToken(response.token);

          console.debug('check cv response', response);

          if (response.Error === true) {
            resolve(response);
            setMiniAppError(
              'Something went wrong while attempting to upload your CV'
            );
          } else if (!response.Success || response.Success === null) {
            dataObj.token = response.token;

            setTimeout(function() {
              runCheck(
                siteConfig,
                dataObj,
                hasCVUploadedStatus,
                setHasCVUploadedStatus,
                setMiniAppFormToken
              );
            }, 1000);
          } else {
            resolve(response);
            setHasCVUploadedStatus(response.Success);
          }
        } else {
          setMiniAppError(
            'Something went wrong while attempting to upload your CV'
          );
        }
      })
      .catch(function(error) {
        console.error(error);
      });
  }

  runCheck(
    siteConfig,
    dataObj,
    hasCVUploadedStatus,
    setHasCVUploadedStatus,
    setMiniAppFormToken
  );
}

function UploadSingleFile(file, presignModel, MiniAppError, setMiniAppError) {
  return new Promise(function(resolve, reject) {
    let formData = new FormData();
    formData.append('Policy', presignModel.Base64Policy);
    formData.append('key', presignModel.Key);
    formData.append('X-Amz-Algorithm', presignModel.Algorithm);
    formData.append('X-Amz-Credential', presignModel.Credentials);
    formData.append('X-Amz-Date', presignModel.Date);
    formData.append('X-Amz-Signature', presignModel.Signature);
    formData.append('X-Amz-meta-filetype', presignModel.FileType);
    formData.append('X-Amz-meta-loggedInUser', presignModel.CreatedByUserId);
    formData.append('file', file);
    formData.append('origin', window.location.origin);
    var xhr = new XMLHttpRequest();
    xhr.open('POST', presignModel.FormActionPoint, true);
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.onload = async function() {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve('success');
      } else {
        reject('fail');
      }
    };
    xhr.onerror = function(err) {
      console.error(err);
      setMiniAppError(err);
      reject(err);
    };

    xhr.send(formData);
  });
}
