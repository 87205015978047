import React from 'react';

import { Link } from 'gatsby';

import renderHTML from 'react-render-html';

import { trackAnalyticsEvent } from '../components/Analytics';
import { useTracking } from 'react-tracking';

const {
  activateApplicationForm,
} = require('../components/MiniAppFormFunctions');

const {
  calculateSalary,
  CheckJobHasNotExpired,
  FormatDateTime,
} = require('../utils/vacancyPage');

const VF_Details_Wrap = (props) => {
  const {
    vacancy,
    apiKey,
    siteConfig,
    vacancyTitle,
    setshowApplication,
    setApplicationFormLoading,
    setMiniAppFormJSON,
    setGdprStatementJSON,
    getJobSourcesJSON,
    setShowMiniAppForm,
    setShowMiniAppFormFrameLoading,
    MiniAppError,
    setMiniAppError,
    miniAppFormToken,
    setMiniAppFormToken,
    location,
    id,
    cookies,
    sessionIDValue,
  } = props;

  // const { trackEvent } = useTracking(
  //   {
  //     page: 'SmartfeedIndividual',
  //     location,
  //     apiKey,
  //     id,
  //     AdvertReference: vacancy.AdvertReference,
  //   },
  //   {
  //     dispatch: (data) =>
  //       trackAnalyticsEvent(
  //         data,
  //         cookies['ap-signals-user-id'],
  //         sessionIDValue,
  //         siteConfig
  //       ),
  //   }
  // );

  return (
    <div className="vf-details-wrap">
      <div className="vf-details">
        {console.log(vacancy)}
        <div className="vf-job-info-container">
          <div className="vf-job-info-title">Salary:</div>
          <div className="vf-job-info-value">
            <span className="vf-category">
              {calculateSalary(
                vacancy.Currency,
                vacancy.SalaryFrom,
                vacancy.SalaryTo,
                vacancy.SalaryType,
                vacancy.SalaryPeriod
              )}
            </span>
          </div>
        </div>
        <div className="vf-job-info-container">
          <div className="vf-job-info-title">Closing Date:</div>
          <div className="vf-job-info-value vf-ExpiryDate">
            {FormatDateTime(vacancy.ExpiryDate)}
          </div>
        </div>
        <div className="vf-job-info-container">
          <div className="vf-job-info-title">Location:</div>
          <div className="vf-job-info-value vf-Location">
            {vacancy.Location ? vacancy.Location + ', ' : ''}
            {vacancy.SubLocation ? vacancy.SubLocation + ', ' : ''}
            {vacancy.PostCode ? vacancy.PostCode + ', ' : ''}
            {vacancy.Country ? vacancy.Country : ''}
          </div>
        </div>
        <div className="vf-job-info-container">
          <div className="vf-job-info-title">Job Type:</div>
          <div className="vf-job-info-value">
            <span className="vf-category">{vacancy.JobType}</span>
          </div>
        </div>
        {vacancy.Attachments &&
          vacancy.Attachments !== [] &&
          vacancy.Attachments.length > 0 && (
            <div className="vf-job-info-container">
              <ul className="vf-Attachments">
                {vacancy.Attachments &&
                  vacancy.Attachments.map((attachment, i) => (
                    <li key={i} id={attachment.S3Name} className="row">
                      <a
                        className="btn-filedownload vf-row"
                        href={attachment.Location}
                        target="noopener noreferrer"
                      >
                        <span className="details vf-col-xs-8">
                          <div className="fal fa-download"></div>
                          <span className="filename">
                            {'Download ' + attachment.FileName.substring(0, 19)}
                            {attachment.FileName.length > 19 ? '...' : ''}
                          </span>
                        </span>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        <div className="vf-job-info-container">
          <div className=" d-flex  align-items-center">
            <img
              src="/img/Disability-Confident-Leader.jpg"
              alt="Disability-Confident-Leader.jpg"
              width="auto"
              height="160px"
            />
          </div>
        </div>
      </div>
      <div className="vf-details-actions d-flex justify-content-between align-items-center d-md-block text-md-center">
        {vacancy.PostingDate &&
          vacancy.ExpiryDate &&
          CheckJobHasNotExpired(vacancy.ExpiryDate) && (
            <>
              <a
                className="btn--action"
                onClick={() => {
                  // trackEvent({
                  //   eventAction: 'activateApplicationForm',
                  //   eventLabel: vacancy.AdvertReference,
                  // });
                  activateApplicationForm(
                    siteConfig,
                    true,
                    setshowApplication,
                    setApplicationFormLoading,
                    setMiniAppFormJSON,
                    setGdprStatementJSON,
                    getJobSourcesJSON,
                    setShowMiniAppForm,
                    setShowMiniAppFormFrameLoading,
                    vacancy.AdvertId,
                    vacancy.Token,
                    vacancy.RequiresCv,
                    MiniAppError,
                    setMiniAppError,
                    miniAppFormToken,
                    setMiniAppFormToken,
                    false,
                    vacancy.CompanyId
                    // trackEvent
                  );
                }}
              >
                Apply now
              </a>
              <div className="vf-indeed-apply-wrap">
                {vacancy.IndeedApplyButtonHTML && (
                  <>{renderHTML(vacancy.IndeedApplyButtonHTML)}</>
                )}
              </div>
              <Link
                className="btn--action-secondary btn btn--secondary"
                id="back-to-careers-link"
                to="/vacancies/"
              >
                Back to careers
              </Link>
            </>
          )}
      </div>
    </div>
  );
};

export default VF_Details_Wrap;
