import React, { useState, useEffect } from 'react';

import { Link } from 'gatsby';

import Map from '../components/Map';

import Layout from '../components/Layout';

import fetchJsonp from 'fetch-jsonp';

import 'url-search-params-polyfill';

import '../css/leaflet.css';

import MiniAppFormForm from '../components/MiniAppForm';
import ScreenReader from '../components/ScreenReader';

import VFDetailsWrap from '../components/VF_Details_Wrap';
import VFShareButtons from '../components/VF_Share_Buttons';

import { useCookies } from 'react-cookie';
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from '../components/Analytics';
import { useTracking } from 'react-tracking';

const isBase64 = require('is-base64');

const base64url = require('base64url');

require('es6-promise').polyfill();

const {
  activateApplicationForm,
} = require('../components/MiniAppFormFunctions');

const {
  CheckJobHasNotExpired,
  generateGoogleJobsSchema,
  listenForIFrameHeight,
} = require('../utils/vacancyPage');

const VacancyPage = ({
  id = 1,
  vacancyTitlePath = false,
  apiKey,
  smartfeedCompanyData,
  appGatewayURL,
  vacanciesRouteLoading,
  vacanciesRouteError,
  location,
  siteConfig,
}) => {
  const params = new URLSearchParams(location.search);
  const extraDataEncoded = params ? params.get('ExtraData') : null;
  const extraDataDecoded = extraDataEncoded
    ? base64url.decode(extraDataEncoded)
    : null;

  const extraDataDecodedSplit = extraDataDecoded
    ? extraDataDecoded.split('|')
    : [];

  const [vacancy, setVacancy] = useState([]);
  const [showApplication, setshowApplication] = useState(false);
  const [applicationFormLoading, setApplicationFormLoading] = useState(false);
  const [miniAppFormToken, setMiniAppFormToken] = useState('');
  const [miniAppFormJSON, setMiniAppFormJSON] = useState('');
  const [gdprStatementJSON, setGdprStatementJSON] = useState('');
  const [jobSourcesJSON, getJobSourcesJSON] = useState([]);
  const [showMiniAppForm, setShowMiniAppForm] = useState(false);
  const [
    showMiniAppFormFrameLoading,
    setShowMiniAppFormFrameLoading,
  ] = useState(false);
  const [miniAppFormSubmitted, setMiniAppFormSubmitted] = useState(false);
  const [MiniAppError, setMiniAppError] = useState('');
  const [MiniAppFormIframe, setMiniAppFormIframe] = useState('');
  const [vacancyTitle, setVacancyTitle] = useState(
    extraDataDecodedSplit &&
      extraDataDecodedSplit[0] &&
      extraDataDecodedSplit[1]
      ? extraDataDecodedSplit[0] + ' | ' + extraDataDecodedSplit[1]
      : extraDataDecodedSplit && extraDataDecodedSplit[0]
      ? extraDataDecodedSplit[0]
      : 'Loading vacancy...'
  );
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [hasCVUploadedStatus, setHasCVUploadedStatus] = useState(null);

  // const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  // const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
  //   'ap-signals-session-id'
  // );

  // const { trackEvent } = useTracking(
  //   {
  //     page: 'SmartfeedIndividual',
  //     location,
  //     apiKey,
  //     id,
  //     AdvertReference: vacancy.AdvertReference,
  //   },
  //   {
  //     dispatch: (data) =>
  //       trackAnalyticsEvent(
  //         data,
  //         cookies['ap-signals-user-id'],
  //         sessionIDValue,
  //         siteConfig
  //       ),
  //   }
  // );

  const fetchVacancy = async (id, vacancyPageAction) => {
    setLoading(true);
    try {
      fetchJsonp(siteConfig.sfAPIEndpoint + '/CareerPage/GetItem?id=' + id, {
        timeout: 15000,
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(json) {
          const responseVacancy = json;
          const resultVacancy = responseVacancy;
          if (resultVacancy) {
            setLoading(false);
            setVacancy(resultVacancy);
            setVacancyTitle(resultVacancy.JobTitle);

            if (vacancyPageAction === 'applyNow') {
              // trackEvent({
              //   eventAction: 'activateApplicationForm_QueryString',
              //   eventLabel: resultVacancy.AdvertReference,
              // });
              activateApplicationForm(
                siteConfig,
                true,
                setshowApplication,
                setApplicationFormLoading,
                setMiniAppFormJSON,
                setGdprStatementJSON,
                getJobSourcesJSON,
                setShowMiniAppForm,
                setShowMiniAppFormFrameLoading,
                resultVacancy.AdvertId,
                resultVacancy.Token,
                resultVacancy.RequiresCv,
                MiniAppError,
                setMiniAppError,
                miniAppFormToken,
                setMiniAppFormToken,
                false,
                resultVacancy.CompanyId
                // trackEvent
              );

              setshowApplication(true);
            }

            return resultVacancy;
          }
        })
        .catch(function(ex) {
          return fetchJsonp(
            siteConfig.sfAPIEndpoint +
              `/CareerPage/GetItem?id=${new Buffer(id, 'base64').toString()}`,
            {
              timeout: 15000,
            }
          )
            .then(function(response) {
              return response.json();
            })
            .then(function(json) {
              const responseVacancy = json;
              const resultVacancy = responseVacancy;
              if (resultVacancy) {
                setLoading(false);
                setVacancy(resultVacancy);
                setVacancyTitle(resultVacancy.JobTitle);

                if (vacancyPageAction === 'applyNow') {
                  // trackEvent({
                  //   eventAction: 'activateApplicationForm_QueryString',
                  //   eventLabel: resultVacancy.AdvertReference,
                  // });
                  activateApplicationForm(
                    siteConfig,
                    true,
                    setshowApplication,
                    setApplicationFormLoading,
                    setMiniAppFormJSON,
                    setGdprStatementJSON,
                    getJobSourcesJSON,
                    setShowMiniAppForm,
                    setShowMiniAppFormFrameLoading,
                    resultVacancy.AdvertId,
                    resultVacancy.Token,
                    resultVacancy.RequiresCv,
                    MiniAppError,
                    setMiniAppError,
                    miniAppFormToken,
                    setMiniAppFormToken,
                    false,
                    resultVacancy.CompanyId
                    // trackEvent
                  );

                  setshowApplication(true);
                }

                return resultVacancy;
              }
            })
            .catch(function(ex) {
              setLoading(false);
              console.debug(ex, 'error');
            });
        });
    } catch (error) {
      setLoading(false);
      console.debug(error.message, 'error');
    }
  };

  // useEffect(() => {
  //   trackAnalyticsPageView(
  //     location,
  //     cookies['ap-signals-user-id'],
  //     sessionIDValue,
  //     siteConfig
  //   );
  // }, [cookies, location, sessionIDValue, siteConfig]);

  useEffect(() => {
    if (!vacanciesRouteLoading && !vacanciesRouteError) {
      listenForIFrameHeight();
      setshowApplication(false);

      let searchParams = new URLSearchParams(location.search);
      let entries = searchParams.entries();

      let vacancyPageAction = null;

      for (let pair of entries) {
        if (pair[0] === 'action') {
          vacancyPageAction = pair[1];
        }
      }

      setTimeout(function() {
        setLoadingText(
          <span>
            If this is taking too long, please{' '}
            <Link to="/">go back to the search page</Link> and re-select a
            vacancy.
          </span>
        );
      }, 3000);

      const timeOut = setTimeout(() => {
        if (isBase64(decodeURIComponent(id)) && vacancyTitlePath) {
          fetchVacancy(
            new Buffer(decodeURIComponent(id), 'base64').toString(),
            vacancyPageAction
          );
        } else {
          fetchVacancy(decodeURIComponent(id), vacancyPageAction);
        }
      }, 25);
      return () => clearTimeout(timeOut);
    }
  }, [id, vacanciesRouteLoading]);

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = new Object({});
    dateFormatOptions.weekday = 'long';
    dateFormatOptions.year = 'numeric';
    dateFormatOptions.month = 'short';
    dateFormatOptions.day = 'numeric';
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  };

  const slugify = function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters

      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-'); // Replace multiple - with single -
  };

  const onError = (e) => {
    e.target.onerror = null;
    e.target.src = '/img/logo.svg';
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={location}
      apiKey={apiKey}
      appGatewayURL={appGatewayURL}
      smartfeedCompanyData={smartfeedCompanyData}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      // trackEvent={trackEvent}
      title={
        loading
          ? extraDataDecodedSplit &&
            extraDataDecodedSplit[0] &&
            extraDataDecodedSplit[1]
            ? extraDataDecodedSplit[0] + ' | ' + extraDataDecodedSplit[1]
            : extraDataDecodedSplit && extraDataDecodedSplit[0]
            ? extraDataDecodedSplit[0]
            : 'Loading vacancy...'
          : vacancyTitle
      }
      description={
        loading
          ? extraDataDecodedSplit && extraDataDecodedSplit[4]
            ? extraDataDecodedSplit[4]
            : extraDataDecodedSplit && extraDataDecodedSplit[1]
            ? 'Check out this vacancy from ' + extraDataDecodedSplit[1]
            : 'Check out this vacancy.'
          : vacancy.Summary
      }
    >
      <div className="main-container main-container--single">
        {!vacanciesRouteLoading &&
          (!vacancy || vacancy === [] || !vacancy.Description) && (
            <div className="page__header-container page__header-container--vacancies-landing page__header-container--vacancies-landing-loading">
              <div className="container-fluid single-loading">
                <div
                  className="row justify-content-center page__header page__header--landing vacancies-landing__header page__header--vacancies-landing"
                  id="listing-top-single-loading"
                >
                  <div className="col-12 col-lg-8">
                    <>
                      <h1>Loading vacancy...</h1>
                      <p className="small-padding">
                        Please wait while we load the vacancy that you
                        requested.
                      </p>
                      {loadingText && <p>{loadingText}</p>}
                    </>
                  </div>
                </div>
              </div>
            </div>
          )}

        {!vacanciesRouteLoading &&
          vacancy &&
          vacancy !== [] &&
          vacancy.Description && (
            <div>
              <div id="ApplicationScrollTarget"></div>
              {!showApplication ? (
                <>
                  {vacancy.PostingDate &&
                    vacancy.ExpiryDate &&
                    CheckJobHasNotExpired(vacancy.ExpiryDate) && (
                      <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                          __html: JSON.stringify(
                            generateGoogleJobsSchema(vacancy)
                          ),
                        }}
                      />
                    )}

                  <div className="page__header-container page__header-container--vacancies-landing page__header-container--vacancies-landing-single">
                    <div className="container">
                      <div
                        className="row justify-content-center page__header page__header--landing vacancies-landing__header page__header--vacancies-landing page__header--vacancies-landing-single"
                        id="listing-top"
                      >
                        <div className="col-12">
                          {!vacancy ||
                            vacancy === [] ||
                            (!vacancy.Description && (
                              <h1 className="vf-jobtitle text-center text-md-left m-0">
                                Loading vacancy...
                              </h1>
                            ))}
                          {vacancy && vacancy !== [] && vacancy.Description && (
                            <div className="d-block d-md-flex justify-content-center justify-content-md-between align-items-center">
                              <h1 className="vf-jobtitle text-center text-md-left m-0">
                                {vacancyTitle}
                              </h1>
                              <div className="job-card-info-logo">
                                <img
                                  className="vf-advert-logo"
                                  alt={vacancy.Region}
                                  src={`/logos/${slugify(vacancy.Region)}.png`}
                                  onError={onError}
                                />
                              </div>
                            </div>
                          )}

                          <div className="d-flex">
                            <div className="job-card-actions">
                              <div className="job-card-reader">
                                <ScreenReader
                                  text={`${vacancy.JobTitle}, 
                                  Salary for this role is ${
                                    vacancy.SalaryType
                                  }, 
                                  this vacancy expires on ${FormatDateTime(
                                    vacancy.ExpiryDate
                                  )}.this role is based in ${
                                    vacancy.Location
                                  }, Postcode ${vacancy.PostCode} in the ${
                                    vacancy.Country
                                  }. The contract type for this position is ${
                                    vacancy.JobType
                                  } / ${vacancy.JobTypeTime}, 
                                          ${vacancy.Description.replace(
                                            /<[^>]*>/g,
                                            ' '
                                          )}`}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-md-flex justify-content-between align-items-end mb-1">
                            <div className="job-title-meta">
                              <span>
                                {'(Reference: ' + vacancy.AdvertReference + ')'}
                              </span>
                            </div>
                            <div id="share-icons">
                              <VFShareButtons
                                vacancy={vacancy}
                                apiKey={apiKey}
                                siteConfig={siteConfig}
                                vacancyTitle={vacancyTitle}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="vacancies-landing">
                    <div className="container border-top">
                      <div className="row clearfix">
                        <div className="col-12 col-lg-4 order-lg-last">
                          <>
                            {vacancy && vacancy !== [] && vacancy.Description && (
                              <VFDetailsWrap
                                vacancy={vacancy}
                                apiKey={apiKey}
                                siteConfig={siteConfig}
                                vacancyTitle={vacancyTitle}
                                setshowApplication={setshowApplication}
                                setApplicationFormLoading={
                                  setApplicationFormLoading
                                }
                                setMiniAppFormJSON={setMiniAppFormJSON}
                                setGdprStatementJSON={setGdprStatementJSON}
                                getJobSourcesJSON={getJobSourcesJSON}
                                setShowMiniAppForm={setShowMiniAppForm}
                                setShowMiniAppFormFrameLoading={
                                  setShowMiniAppFormFrameLoading
                                }
                                MiniAppError={MiniAppError}
                                setMiniAppError={setMiniAppError}
                                miniAppFormToken={miniAppFormToken}
                                setMiniAppFormToken={setMiniAppFormToken}
                                location={location}
                                id={id}
                                // sessionIDValue={sessionIDValue}
                                // cookies={cookies}
                              />
                            )}
                          </>
                        </div>
                        <div className="col-12 col-lg-8">
                          <div className="smartfeed-spacing">
                            <div className="row vacancyDetailsWrap">
                              <div className="col-12 job-description">
                                <div className="vf-JobDescription">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: vacancy.Description,
                                    }}
                                  />
                                </div>

                                <p
                                  className="vf-benefits"
                                  style={{ fontSize: '18px' }}
                                >
                                  <strong>Benefits:</strong> <br></br>
                                  {vacancy.Benefits}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col vf-map">
                          {vacancy.Latitude !== '' &&
                            vacancy.Latitude !== null &&
                            vacancy.Latitude !== 0 &&
                            vacancy.Longitude !== '' &&
                            vacancy.Longitude !== null &&
                            vacancy.Longitude !== 0 && (
                              <Map
                                latitude={Number(vacancy.Latitude)}
                                longitude={Number(vacancy.Longitude)}
                                zoom={12}
                                scrollWheelZoom={false}
                                tooltipText={vacancy.JobTitle}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <section>
                    <div>
                      <div className="container ">
                        <div className="row clearfix">
                          <div className="col-md-12 column">
                            {' '}
                            <div>
                              <div className="smartfeed-spacing">
                                <article tabIndex="-1">
                                  <div className="ctacard ctacard-application">
                                    <div className="ctacard__body d-flex justify-content-center">
                                      <div className="col-12">
                                        <h4 className="ctacard__body__title">
                                          Apply for this role
                                        </h4>
                                        <p className="ctacard__body__summary"></p>
                                        <p>
                                          Submit your application today with our
                                          simple application process.
                                        </p>
                                        <p></p>
                                        {vacancy.PostingDate &&
                                          vacancy.ExpiryDate &&
                                          CheckJobHasNotExpired(
                                            vacancy.ExpiryDate
                                          ) && (
                                            <a
                                              className="btn--action"
                                              onClick={() => {
                                                // trackEvent({
                                                //   eventAction:
                                                //     'activateApplicationForm',
                                                //   eventLabel:
                                                //     vacancy.AdvertReference,
                                                // });
                                                activateApplicationForm(
                                                  siteConfig,
                                                  true,
                                                  setshowApplication,
                                                  setApplicationFormLoading,
                                                  setMiniAppFormJSON,
                                                  setGdprStatementJSON,
                                                  getJobSourcesJSON,
                                                  setShowMiniAppForm,
                                                  setShowMiniAppFormFrameLoading,
                                                  vacancy.AdvertId,
                                                  vacancy.Token,
                                                  vacancy.RequiresCv,
                                                  MiniAppError,
                                                  setMiniAppError,
                                                  miniAppFormToken,
                                                  setMiniAppFormToken,
                                                  false,
                                                  vacancy.CompanyId
                                                  // trackEvent
                                                );
                                              }}
                                            >
                                              Apply now
                                            </a>
                                          )}
                                        <div className="vf-JobActions">
                                          <div id="share-icons">
                                            <VFShareButtons
                                              vacancyTitle={vacancyTitle}
                                              vacancy={vacancy}
                                              apiKey={apiKey}
                                              siteConfig={siteConfig}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <div className="vacancy-application-wrapper">
                  <div className="vacancy-application-topbar vacancy-application-topbar---standard-app">
                    <div className="container">
                      <div className="row job-title-row">
                        <div className="col-12 col-lg-6 job-title-app">
                          <h1>{vacancyTitle + ' application'}</h1>
                        </div>
                        <div className="col-12 col-lg-6 back-to-description">
                          <a
                            className="nav-action-sm"
                            onClick={() => {
                              // trackEvent({
                              //   eventAction: 'deactivateApplicationForm',
                              //   eventLabel: vacancy.AdvertReference,
                              // });
                              activateApplicationForm(
                                siteConfig,
                                false,
                                setshowApplication,
                                setApplicationFormLoading,
                                setMiniAppFormJSON,
                                setGdprStatementJSON,
                                getJobSourcesJSON,
                                setShowMiniAppForm,
                                setShowMiniAppFormFrameLoading,
                                vacancy.AdvertId,
                                vacancy.Token,
                                vacancy.RequiresCv,
                                MiniAppError,
                                setMiniAppError,
                                miniAppFormToken,
                                setMiniAppFormToken,
                                false,
                                vacancy.CompanyId
                                // trackEvent
                              );
                            }}
                          >
                            Go back to the description
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="miniAppFormWrapper">
                    <div className="container">
                      <div id="miniAppForm">
                        {miniAppFormJSON &&
                        miniAppFormJSON.config &&
                        miniAppFormJSON.config.Fields ? (
                          <MiniAppFormForm
                            siteConfig={siteConfig}
                            showMiniAppForm={showMiniAppForm}
                            applicationFormLoading={applicationFormLoading}
                            miniAppFormJSON={miniAppFormJSON}
                            setMiniAppFormJSON={setMiniAppFormJSON}
                            miniAppFormToken={miniAppFormToken}
                            setMiniAppFormToken={setMiniAppFormToken}
                            gdprStatementJSON={gdprStatementJSON}
                            jobSourcesJSON={jobSourcesJSON}
                            setShowMiniAppForm={setShowMiniAppForm}
                            showMiniAppFormFrameLoading={
                              showMiniAppFormFrameLoading
                            }
                            setShowMiniAppFormFrameLoading={
                              setShowMiniAppFormFrameLoading
                            }
                            miniAppFormSubmitted={miniAppFormSubmitted}
                            setMiniAppFormSubmitted={setMiniAppFormSubmitted}
                            MiniAppFormIframe={MiniAppFormIframe}
                            setMiniAppFormIframe={setMiniAppFormIframe}
                            MiniAppError={MiniAppError}
                            setMiniAppError={setMiniAppError}
                            hasCVUploadedStatus={hasCVUploadedStatus}
                            setHasCVUploadedStatus={setHasCVUploadedStatus}
                            // trackEvent={trackEvent}
                          />
                        ) : (
                          <div className="loading-panel">
                            <div className="loading-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span className="loading-text">
                              Please wait while we load the form's
                              configuration...
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
      </div>
    </Layout>
  );
};

export default VacancyPage;
